<template>
  <v-container fluid>
    <v-form ref="form" lazy-validation>
      <v-progress-linear v-if="this.tmplLoading" indeterminate />
      <v-row class="pl-4 pr-4">
        <v-col class="text-right pt-0 pb-0" cols="12" lg="4" md="6" sm="12">
          <v-checkbox
            :true-value="1"
            :false-value="0"
            v-model="form.printTmplOptionObj.show_sellername"
            :label="$t('settings.lang_printerManagerShowSellerName')"
          />
        </v-col>

        <v-col class="text-right pt-0 pb-0" cols="12" lg="4" md="6" sm="12">
          <v-checkbox
            :true-value="1"
            :false-value="0"
            v-model="form.printTmplOptionObj.show_sellerid"
            :label="$t('settings.lang_receipt_show_sellerid')"
          />
        </v-col>

        <v-col class="text-right pt-0 pb-0" cols="12" lg="4" md="6" sm="12">
          <v-checkbox
            :true-value="1"
            :false-value="0"
            v-model="form.printTmplOptionObj.printWareDescr2Receipt"
            :label="$t('erp.lang_receiptAttachDescrtoWareName')"
          />
        </v-col>

        <v-col class="text-right pt-0 pb-0" cols="12" lg="4" md="6" sm="12">
          <v-checkbox
            :true-value="1"
            :false-value="0"
            v-model="form.printTmplOptionObj.fontSizeBody"
            :label="$t('settings.lang_useLargerFontBodyReceipt')"
          />
        </v-col>

        <v-col class="text-right pt-0 pb-0" cols="12" lg="4" md="6" sm="12">
          <v-checkbox
            :true-value="1"
            :false-value="0"
            v-model="form.printTmplOptionObj.displayPriceChangesOnBon"
            :label="$t('settings.lang_receiptDisplayChangeofPrice')"
          />
        </v-col>

        <v-col class="text-right pt-0 pb-0" cols="12" lg="4" md="6" sm="12">
          <v-checkbox
            :true-value="1"
            :false-value="0"
            v-model="form.printTmplOptionObj.show_sellernameSalutation"
            :label="$t('settings.lang_displayVendorSalutationReceipt')"
          />
        </v-col>

        <v-col class="text-right pt-0 pb-0" cols="12" lg="4" md="6" sm="12">
          <v-checkbox
            :true-value="1"
            :false-value="0"
            v-model="form.printTmplOptionObj.displayBonRetourCodeBottom"
            :label="$t('erp.lang_receiptBonWizzardDisplayRetourBarcodePo')"
          />
        </v-col>

        <v-col class="text-right pt-0 pb-0" cols="12" lg="4" md="6" sm="12">
          <v-checkbox
            :true-value="1"
            :false-value="0"
            v-model="form.printTmplOptionObj.displayTableNo"
            :label="$t('settings.lang_printTableNoOnReceipt')"
          />
        </v-col>

        <v-col class="pt-5 pb-2" cols="12" lg="4" md="6" sm="12">
          <p class="ma-0">{{ $t('settings.lang_displayDiscountAs') }}</p>

          <v-btn-toggle
            v-model="form.printTmplOptionObj.discountDisplay"
            color="primary"
            mandatory
          >
            <v-btn value="percent" class="ma-0" text>
              {{ $t('settings.lang_displayDiscountPercent') }}
            </v-btn>
            <v-btn value="number" class="ma-0" text>
              {{ $t('settings.lang_displayDiscountNumber') }}
            </v-btn>
          </v-btn-toggle>
        </v-col>

        <v-col class="pa-0" cols="12">
          <v-divider class="pa-0 ma-0" />
        </v-col>
        <v-col class="text-right pt-0 pb-0" cols="12">
          <v-btn
            :disabled="loading"
            :loading="loading"
            color="primary"
            @click="save"
          >
            {{ this.$t('generic.lang_save') }}
          </v-btn>
        </v-col>
        <v-col class="pa-0">
          <div id="onScreenKeyboardDiv">
            <vue-touch-keyboard
              v-if="touchKeyboard.visible"
              id="onScreenKeyboard"
              :accept="hideTouchKeyboard"
              :cancel="hideTouchKeyboard"
              :defaultKeySet="touchKeyboard.keySet"
              :input="touchKeyboard.input"
              :layout="touchKeyboard.layout"
              :options="touchKeyboard.options"
              class="internalWidth"
            />
          </div>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import mixin from '@/mixins/KeyboardMixIns';
import { ENDPOINTS } from '@/config';
import { Events } from '@/plugins/events';

export default {
  name: 'ReceiptDataComponent',
  mixins: [mixin],
  data: () => {
    return {
      loading: false,
      tmplLoading: false,
      tmplHeaderObj: '',
      tmplFooterObj: '',
      form: {
        printTmplOptionObj: {
          show_sellername: 0,
          show_sellerid: 0,
          printWareDescr2Receipt: 0,
          fontSizeBody: 0,
          displayPriceChangesOnBon: 0,
          show_sellernameSalutation: 0,
          displayTableNo: 0,
          discountDisplay: 'percent',
          displayBonRetourCodeBottom: 0,
        },
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    save() {
      if (!this.$refs.form.validate()) return;
      this.loading = true;
      this.form.printTmplId = this.form.id;
      this.form.updateType = 'template';
      this.axios
        .post(ENDPOINTS.PRINTSETTINGS.TEMPLATES.UPDATE, this.form)
        .then((res) => {
          if (res.data.success) {
            Events.$emit('showSnackbar', {
              message: this.$t('erp.lang_itemSuccessfulEdited'),
              color: 'success',
            });
            this.$refs.form.reset();
            this.dialog = false;
            this.$router.push('/settings/printerSettings/receiptTmpl');
          } else if (!res.data.success) {
            Events.$emit('showSnackbar', {
              color: 'error',
              message: res.data.msg,
            });
          } else {
            Events.$emit('showSnackbar', {
              color: 'error',
              message: this.$t('generic.lang_anErrorOccurredPLSTryAgain'),
            });
          }
        })
        .catch((err) => {
          Events.$emit('showSnackbar', {
            color: 'error',
            message: err.message,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getData() {
      this.tmplLoading = true;
      this.axios
        .post(ENDPOINTS.PRINTSETTINGS.TEMPLATES.GET, {
          printTmplID: this.$route.params.id,
        })
        .then((res) => {
          if (res.data.success) {
            this.form = Object.assign({}, res.data.printerTmpl);
            if (
              JSON.parse(res.data.printerTmpl.printTmplOptionObj) &&
              JSON.parse(res.data.printerTmpl.printTmplOptionObj).length < 1
            ) {
              this.form.printTmplOptionObj = {
                show_sellername: 0,
                show_sellerid: 0,
                printWareDescr2Receipt: 0,
                fontSizeBody: 0,
                displayPriceChangesOnBon: 0,
                show_sellernameSalutation: 0,
                displayTableNo: 0,
                displayBonRetourCodeBottom: 0,
              };
            } else {
              this.form.printTmplOptionObj = JSON.parse(
                res.data.printerTmpl.printTmplOptionObj
              );
            }
          } else if (!res.data.success) {
            Events.$emit('showSnackbar', {
              message: res.data.msg,
              color: 'error',
            });
          } else {
            Events.$emit('showSnackbar', {
              color: 'error',
              message:
                this.$t('generic.lang_anErrorOccurredPLSTryAgain') + res.data,
            });
          }
        })
        .catch((err) => {
          Events.$emit('showSnackbar', {
            color: 'error',
            message: this.$t('generic.lang_anErrorOccurredPLSTryAgain') + err,
          });
        })
        .finally(() => {
          this.tmplLoading = false;
        });
    },
  },
  computed: {},
};
</script>

<style scoped></style>
